@import './colors';
@import 'bulma/bulma';
@import './animations.scss';


html,body{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background: $background;
}

.header{
    background: $success;
    background: linear-gradient(180deg, $success 0%, $primary 100% );
}

.card{
    border-radius: 1rem;
    height: 100%;
}

.header.subpage{
    overflow: hidden;
    position: relative;

}

.header.subpage > img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    opacity: 0.4;

}
.header.subpage > .hero-body{
    position: relative;
    z-index: 1;
}

.header-wave{
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transform: translateY(-100px);
    width: 100vw;
    max-height: 100px;
}

.footer{
    border-top: 10px solid $success;
}

.card-title{
    color: $primary;
    font-weight: 700;
}